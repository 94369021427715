import React from "react";
import AnonymousController from "../Common/Controller/AnonymousController";
import Seo from "../Common/Model/Seo";

import AuthController from "../Common/Security/AuthController";
import CallToActionHeaderActionButton from "../Common/Widget/CallToActionHeaderActionButton/CallToActionHeaderActionButton";
import HeaderAuthenticatedButtonWidget from "../Common/Widget/HeaderAuthenticatedButtonWidget/HeaderAuthenticatedButtonWidget";
import MyDashboardController from "./Manager/MyDashboardController";

export default class HomeController extends AnonymousController {
    static _PATH = '/';
    constructor(props) {
        super(props);


        this.state = {
            isLoggedIn: props.isLoggedIn || false,
            domains: [],
            meta: {}

        }
    }

    componentDidMount() {
        Seo.getSeoMeta().then(meta => {
            this.setState({ meta: meta });
        });

        AuthController.isLoggedIn().then(isLoggedIn => {
            this.setState({ isLoggedIn: isLoggedIn });
        });
    }





    // here we we render the one that is correct
    // we dont show anything until we know if the user is logged in or not
    render() {



        return super.render({
            meta: this.state.meta,
            extraActions: <>
                <CallToActionHeaderActionButton path={MyDashboardController._PATH} isAuthBased={true} text={'Manager'} />
                <HeaderAuthenticatedButtonWidget />
            </>,
            inviewToRender: <div className="HomePage">
                <div className="CenteredBox">
                    
                    <div>
                        
                    </div>

                </div>
            </div>
        })
    }
}