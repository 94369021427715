import React from "react";
import AuthController from "../Security/AuthController";


export default class AuthenticatedController extends React.Component {


    componentDidMount() {
        AuthController.restrictedPageAccessForUsers();
    }


    render() {
        return <h5>You must be logged in</h5>
    }
}