import React from "react";
import "./DashboardSidePanelWidget.scss";

export default class DashboardSidePanelWidget extends React.Component {



    render(){
        return <div className="side-widget" is-loading={this.props.isLoading === true? 1 : 0}>
           {this.props.content}
        </div>
    }
}