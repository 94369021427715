import ApiFetch from "../Common/Api/ApiFetch";
import ApiPrefix from "../Common/Api/ApiPrefix";
import DomainAvailable from "../Model/DomainAvailable";



export default class DomainRepository {


    static async searchDomain(domainName) {
   
        let url = new URL(ApiPrefix.getByEnv('DOMAIN') + '/domains/available');
        url.searchParams.set('d', domainName);

        try {
          let response = await ApiFetch.authenticated(url, {
            method: "GET"
          });
    
          if (response.ok) {
            const json = await response.json();
            return DomainAvailable.fromList(json);
      
          }
        } catch (e) {
          console.log(e);
        }
      }
}