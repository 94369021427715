import DashboardController from "../../Common/Dashboard/DashboardController";
import AuthController from "../../Common/Security/AuthController";
import MyDomainsController from "./MyDomainsController";
import ManageDomain from "./ManageDomain";
import DomainApi from "../../Api/DomainApi";

export default class ManageDomainParameters extends DashboardController {
    static _PATH = '/manager/domain/parameters';

    constructor(props) {
        super(props);

        this.state = {
            domain: {},
            domainName: null,
        }
    }



    static renderButtons() {
        return ManageDomain.renderButtons();

    }

    componentDidMount() {
        super.componentDidMount();
        AuthController.isLoggedIn().then(isLoggedIn => {
            this.setState({isLoggedIn: isLoggedIn});

            if(isLoggedIn) {
                // get domains
                let url = new URL(window.location.href);
                if(!url.searchParams.has('d')) {
                    window.open(MyDomainsController._PATH, '_self');
                }
                const domainName = url.searchParams.get('d');
                this.setState({domainName: domainName}); //

                DomainApi.getMyDomains().then(domains => {
                   
                    if(domains.some(d => d.name === domainName)) {
                        // Check if the user has access to this domain
                        const domain = domains.find(d => d.name === domainName)
                        this.setState({domain: domain});
                    } else {
                        // you dont have access to this one
                        // redirect user to domains page
                        window.open(MyDomainsController._PATH, '_self');
                    }
                   
                });

            }


        });
    }

    renderContent() {
      
        
        return <>{this.state.domain.name}</>
    }

    render() {

        return super.render({
            dashboardPanelContent: ManageDomain.renderButtons(),
            dashboardContent: this.renderContent(),
        })
    }
}