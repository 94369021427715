import React from "react";
import AuthController from "../../Security/AuthController";
import loadingWidget from "../LoadingWidget";
import './CallToActionHeaderActionButton.scss';

export default class CallToActionHeaderActionButton extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            show: undefined,
        }

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        window.location.pathname = this.props.path;
    }


    componentDidMount() {
        if (this.props.isAuthBased) {
            AuthController.isLoggedIn().then(isLoggedIn => {
                this.setState({ show: isLoggedIn });
            });
        }

    }



    render() {

        if (this.state.show === undefined) {
            return loadingWidget.buttonLoadingSpinner();
        } else if (this.state.show === true) {

            return <button onClick={this.onClick} className="call-to-action">
                {this.props.text || 'My button'}
            </button>

        } else {
            return <></>
        }

    }
}