import AuthLoginToken from "../Model/AuthLoginToken";
import AppRedirect from "../Utility/AppRedirect";
import ArrayUtility from "../Utility/ArrayUtility";
// import EncryptedLocalStorageUtility from "../Utility/EncryptedLocalStorageUtility";
// import EncryptedSessionStorageUtility from "../Utility/EncryptedSessionStorageUtility";
import LocalStorageUtility from "../Utility/Storage/LocalStorageUtility";
import SessionStorageUtility from "../Utility/Storage/SessionStorageUtility";

import AuthApi from "./AuthApi";


export default class ApiFetch {


    static generateTimestamp() {
        const currentDate = new Date();
        const currentTimestamp = currentDate.getTime();
        return currentTimestamp;
    };

    /**
     * 
     * @returns {Promise<Response>| undefined}
     */
    static async handleError(error, errorStatus = 401, {
        onAuthRetry = async () => { },
        onFail = async () => { },

    }) {

        if (error === errorStatus) {
            console.error("You arent logged in. Let's refresh !");
            const refreshResponse = await AuthApi.refresh();
            if (refreshResponse) {
                console.info("We refreshed successfully.");
                return await onAuthRetry(refreshResponse) ?? null;
            } else {
                console.info("We could'nt refresh successfully.");
                // bad
                return await onFail() ?? null;
            }
        }


        console.info(error.status, 'Unhandled error');
        return undefined;
    }

    static defaultAuthenticatedInit = {
        headers: {
            "Content-Type": "application/json",
            "accept": "application/json",
            "Authorization": AuthLoginToken.getAuthorizationBearer(),
        }
    };

    static defaultInit = {
        headers: {
            "Content-Type": "application/json",
            "accept": "application/json",
        }
    };

    /**
     * 
     * @param {RequestInfo|URL} url 
     * @param {RequestInit} init 
     * @returns {Promise<Response>|null}
     */
    static async public(url, init) {

        // here we let the users override or add new properties to this object
        const finalInit = ArrayUtility.recursiveMerge(
            ApiFetch.defaultInit,
            init
        );

        url.searchParams.set('timestamp', ApiFetch.generateTimestamp());

        try {
            let response = await fetch(url, finalInit);

            if (response.status === 401) {
                throw response.status;
            }

            return response;
        } catch (e) {
            console.log(e);
            // handle error

        }
    }

    /**
     * 
     * @param {RequestInfo} url 
     * @param {RequestInit} init 
     * @returns {Promise<any>}
     */
    static async cachedPublic(url, init = null, options = {
        forceRefresh: false,
        cacheExpirationTime: 60 * 1000 // 1 minute
    }) {
        if (options.forceRefresh !== true) {
            // const cachedData = window.location.protocol === 'https:' ?
            //     await EncryptedLocalStorageUtility.getCachedData(url, options.cacheExpirationTime) :
            //     LocalStorageUtility.getCachedData(url, options.cacheExpirationTime);

            const cachedData = LocalStorageUtility.getCachedData(url, options.cacheExpirationTime);

            if (cachedData) {
                return cachedData;
            }
        }

        // here we let the users override or add new properties to this object
        const finalInit = ArrayUtility.recursiveMerge(
            ApiFetch.defaultInit,
            init
        );


        try {
            let response = await fetch(url, finalInit);


            if (response.ok) {
                const json = await response.json();

                // window.location.protocol === 'https:' ?
                //     await EncryptedLocalStorageUtility.cacheData(url, json) :
                //     LocalStorageUtility.cacheData(url, json);

                LocalStorageUtility.cacheData(url, json)

                return json;
            }


            throw response.status;
        } catch (e) {
            console.log(e);

            // handle errors here
        }
    }


    /**
     * 
     * @param {RequestInfo} url 
     * @param {RequestInit} init 
     * @returns {Promise<Response>}
     */
    static async authenticated(url, init = null) {

        // here we let the users override or add new properties to this object
        const finalInit = ArrayUtility.recursiveMerge(
            ApiFetch.defaultAuthenticatedInit,
            init
        );
        
        url.searchParams.set('timestamp', ApiFetch.generateTimestamp());

        try {
            let response = await fetch(url, finalInit);

            if (response.status === 401) {
                throw response.status;
            }

            return response;
        } catch (e) {
            return await ApiFetch.handleError(e, 401, {
                onAuthRetry: async () => {
                    // good, retry the request
                    const res = await ApiFetch.authenticated(url, init);

                    if (res) {
                        console.info("Finally ! Request working");
                        return res;
                    }
                },
                onFail: async () => {
                    // bad
                    // could'nt retry
                    console.info("You are definitely not logged in. Your JWT expired probably");

                    // window.location.protocol === 'https:' ?
                    //     EncryptedSessionStorageUtility.clear() :
                    //     SessionStorageUtility.clear();

                    AuthLoginToken.clear();
                    SessionStorageUtility.clear();
                    await AppRedirect.toSSO();
                }
            });
        }
    }


    /**
     * 
     * @param {RequestInfo} url 
     * @param {RequestInit} init 
     * @returns {Promise<any>}
     */
    static async cachedAuthenticated(url, init = null, options = {
        forceRefresh: false,
        cacheExpirationTime: 60 * 1000 // 1 minute
    }) {
        if (options.forceRefresh !== true) {
            // const cachedData = window.location.protocol === 'https:' ?
            //     await EncryptedSessionStorageUtility.getCachedData(url, options.cacheExpirationTime)
            //     : SessionStorageUtility.getCachedData(url, options.cacheExpirationTime);

            const cachedData = SessionStorageUtility.getCachedData(url, options.cacheExpirationTime);
            if (cachedData) {
                return cachedData;
            }
        }



        // here we let the users override or add new properties to this object
        const finalInit = ArrayUtility.recursiveMerge(
            ApiFetch.defaultAuthenticatedInit,
            init
        );

        try {
            let response = await fetch(url, finalInit);


            if (response.ok) {
                const json = await response.json();
                // window.location.protocol === 'https:' ?
                //     await EncryptedSessionStorageUtility.cacheData(url, json) :
                //     SessionStorageUtility.cacheData(url, json);

                SessionStorageUtility.cacheData(url, json)
                return json;
            }

            throw response.status;
        } catch (e) {
            return await ApiFetch.handleError(e, 401, {
                onAuth: async () => {
                    // good, retry the request
                    let res = await ApiFetch.authenticated(url, init);

                    if (res) {
                        console.info("Finally ! Request working");
                        return res;
                    }
                },
                onFail: async () => {
                    // bad
                    // could'nt retry
                    console.info("You are definitely not logged in. Your JWT expired probably");

                    // window.location.protocol === 'https:' ?
                    //     EncryptedSessionStorageUtility.clear() :
                    //     SessionStorageUtility.clear();

                    SessionStorageUtility.clear();

                    await AppRedirect.toSSO();
                }
            });

        }
    }
}