import React from "react";

import DashboardController from "../../Common/Dashboard/DashboardController";
import SidePanelButtonWidget from "../../Common/Dashboard/SidePanelWidget/SidePanelButtonWidget/SidePanelButtonWidget";
import AuthController from "../../Common/Security/AuthController";
import MyDashboardController from "./MyDashboardController";
import { FaCreditCard } from "react-icons/fa";
import DomainApi from "../../Api/DomainApi";

export default class MyBillingsController extends DashboardController {
    static _PATH = '/manager/bills';
    constructor(props) {
        super(props);

        this.state = {
            domains: []
        }
    }

    static RenderButton(props) {
        return <SidePanelButtonWidget text={'Bills'} path={MyBillingsController._PATH} icon={<FaCreditCard />} />
    }


    componentDidMount() {
        super.componentDidMount();
        AuthController.isLoggedIn().then(isLoggedIn => {
            this.setState({isLoggedIn: isLoggedIn});

            if(isLoggedIn) {
                // get domains
                DomainApi.getMyDomains().then(domains => {
                    this.setState({domains: domains});
                });

            }


        });
    }

    extractDomainFromUrl() {
        let url = new URL(window.location.href);

        let domain = url.searchParams.get('domain');

        return domain;
    }



    render() {

       
        return super.render({
            dashboardPanelContent: MyDashboardController.renderPanelContent(),
            dashboardContent:  this.state.dashboardContent,
        })

    }
}