
import ApiPrefix from "../Common/Api/ApiPrefix";
import DomainStatus from "./DomainStatus";

export default class Domain {
  constructor({id = 0, name, expireAt, status, domainNameServers, domainNameServerRecords, domainPermissions, isDefaultNameServers}) {
    this.id = id;
    this.name = name;
    this.expireAt = expireAt;
    this.status = new DomainStatus(status);
    this.domainNameServers = domainNameServers;
    this.domainNameServerRecords = domainNameServerRecords;
    this.domainPermissions = domainPermissions;
    this.isDefaultNameServers = isDefaultNameServers;

   
  }

  static buildIri(id) {
    const api = ApiPrefix.apiDomains.find((d) => d.API === "DOMAIN");
    const version = api.version;
    let url = `/v{version}/domains/{id}`;
    url = url.replace('{version}', version);
    url = url.replace('{id}', id);
    return url;
  }


  /**
     *
     * @param {Array} objectList
     * @returns {Domain[]}
     */
  static fromList(objectList = []) {
    if (objectList.length === 0) 
      return objectList;
    return objectList.map(o => new Domain(o));
  }
}