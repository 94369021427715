

export default class DomainAvailable {
    constructor(props) {
        this.isAvailable = props.isAvailable;
        this.domainName = props.domainName;
        this.provider = props.provider;
        this.isTransferable = props.isTransferable;
    }


    /**
       *
       * @param {Array} objectList
       * @returns {DomainAvailable[]}
       */
    static fromList(objectList = []) {
        if (objectList.length === 0)
            return objectList;
        return objectList.map(o => new DomainAvailable(o));
    }

}