import React from "react";

import DashboardController from "../../Common/Dashboard/DashboardController";
import SidePanelButtonWidget from "../../Common/Dashboard/SidePanelWidget/SidePanelButtonWidget/SidePanelButtonWidget";
import AuthController from "../../Common/Security/AuthController";
import MyDashboardController from "./MyDashboardController";
import { FaSearch } from "react-icons/fa";
import './scss/SearchController.scss';
import DomainRepository from "../../Repository/DomainRepository";

export default class SearchController extends DashboardController {
    static _PATH = '/manager/domain/search';
    constructor(props) {
        super(props);

        this.state = {
            search: this.extractDomainFromUrl(),
            domains: [],
            results: []
        }
    }

    async asyncInitState() {
        let domainAvailableList = await DomainRepository.searchDomain(this.state.search);


        this.setState({results: domainAvailableList})
    }

    static RenderButton(props) {
        return <SidePanelButtonWidget
            text={'Search a new domain'}
            path={SearchController._PATH}
            icon={<FaSearch />} />
    }


    componentDidMount() {
        super.componentDidMount();
        AuthController.isLoggedIn().then(isLoggedIn => {
            this.setState({ isLoggedIn: isLoggedIn });

            if (isLoggedIn) {
                // get domains
                // DomainRepository.getMyDomains().then(domains => {
                //     this.setState({ domains: domains });
                // });

                // get each domains we are looking for
                // just cal one API to check in WHOIS database
                // then show the list

                this.asyncInitState();
            }


        });
    }

    extractDomainFromUrl() {
        let url = new URL(window.location.href);
        let domain = url.searchParams.get('domain');
        return domain;
    }


    searchDomain(e) {

        if (e.key === 'Enter') {
            const value = e.target.value;

            if (value) {
                let url = new URL(window.location.href);
                url.pathname = SearchController._PATH;
                url.searchParams.set('domain', value);
                window.location.href = url.href;
            }


        }


    }


    render() {


        return super.render({
            dashboardPanelContent: MyDashboardController.renderPanelContent(),
            dashboardContent: <>
                <div className="searchDomain">
                    <input onKeyUp={this.searchDomain} type='text' placeholder='Search domain availability' defaultValue={this.state.search} />
                </div>

                <div className="searchResults">
                    {this.state.results.map((e) => <div>
                        <h4>{e.domainName}</h4>
                        <p>{e.isAvailable === true ? "available" : "unavailable"}</p>
                        <p>9.99 EUR</p>
                        <button>Buy</button>
                        <button>Transfer</button>
                    </div>)}
                </div>

            </>,
        })

    }
}