import React from "react";
import "./DashboardContentViewerWidget.scss";


export default class DashboardContentViewerWidget extends React.Component {
    // constructor(props) {
    //     super(props);

    //     this.state = {
    //         content: props.content
    //     }
    // }



    render(){
        return <div className="content-viewer" is-loading={this.props.isLoading === true? 1 : 0}>
            {this.props.content}
        </div>
    }
}