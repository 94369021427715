import DashboardController from "../../Common/Dashboard/DashboardController";
import AuthController from "../../Common/Security/AuthController";
import MyDomainsController from "./MyDomainsController";
import ManageDomain from "./ManageDomain";
import DateHandler from "../../Common/Utility/DateHandler";
import DomainApi from "../../Api/DomainApi";

export default class ManageDomainUserAccess extends DashboardController {
    static _PATH = '/manager/domain/users';

    constructor(props) {
        super(props);

        this.state = {
            domain: null,
            domainName: null,
        }
    }



    static renderButtons() {
        return ManageDomain.renderButtons();

    }

    componentDidMount() {
        super.componentDidMount();
        AuthController.isLoggedIn().then(isLoggedIn => {
            this.setState({isLoggedIn: isLoggedIn});

            if(isLoggedIn) {
                // get domains
                let url = new URL(window.location.href);
                if(!url.searchParams.has('d')) {
                    window.open(MyDomainsController._PATH, '_self');
                }
                const domainName = url.searchParams.get('d');
                this.setState({domainName: domainName}); //

                DomainApi.getMyDomains().then(domains => {
                   
                    if(domains.some(d => d.name === domainName)) {
                        // Check if the user has access to this domain
                        const domain = domains.find(d => d.name === domainName);
                        
                        this.setState({domain: domain});
                    } else {
                        // you dont have access to this one
                        // redirect user to domains page
                        window.open(MyDomainsController._PATH, '_self');
                    }
                   
                });

            }


        });
    }

    renderContent() {
      
        
        return <table className="domain-table">
        <thead>
            <tr> 
                <th> Name</th>
                <th> Role</th>
                <th> Access given at</th>
            </tr>
          
            

        </thead>
        <tbody>
            {this.state.domain !== null ? this.state.domain.domainPermissions.map(d => 
                <tr onClick={() => this.redirectToDomain(d)} key={d.id}>
                    <td>{d.user.firstName + " " + d.user.lastName}</td>
                    <td>{d.role}</td>
                    <td>{DateHandler.formatDate(d.createdAt)}</td>
                </tr>
            ) : ''}
        </tbody>

    </table>
    }

    render() {

        return super.render({
            dashboardPanelContent: ManageDomain.renderButtons(),
            dashboardContent: this.renderContent(),
        })
    }
}