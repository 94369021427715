import ApiFetch from "../Common/Api/ApiFetch";
import ApiPrefix from "../Common/Api/ApiPrefix";
import domainNameServerRecord from "../Model/DomainNameServerRecord";


export default class DomainNSRecordApi {

    static async add(dnsrecord, domain) {
        return await DomainNSRecordApi.save(dnsrecord, domain);
    }

    static async save(dnsr, domain) {

        const response = await ApiFetch.authenticated(ApiPrefix.getByEnv('DOMAIN') + `/domains/${domain.id}/dns/record`, {
            method: 'POST',
            body: JSON.stringify(new domainNameServerRecord({
                ...dnsr,
                domainId: domain.id
            }))
        });

        if (response.ok) {
            if(response.status === 201) {
                return true;
            }

        
            return true;
        }
        return false;

    }


    static async reset(domain) {
        const response = await ApiFetch.authenticated(ApiPrefix.getByEnv('DOMAIN') + `/domains/${domain.id}/dns/records/reset`, {
            method: 'PUT',
            body: JSON.stringify({})
        });


        if (response.ok) {
            return true;
        }
        return false;
    }


    static async deleteAll(domain) {
        const response = await  ApiFetch.authenticated(ApiPrefix.getByEnv('DOMAIN') + `/domains/${domain.id}/dns/records/clear`, {
            method: 'DELETE',
        });

        if (response.ok) {
            return true;
        }
        return false;
    }





    static async delete(domain) {
        const response = await ApiFetch.authenticated(ApiPrefix.getByEnv('DOMAIN', 1) + `/domains/${domain.id}/dns/records/`, {
            method: 'DELETE'
        });


        if (response.ok) {
            return true;
        }
        return false;
    }

    static async update(domain) {
        const response = await ApiFetch.authenticated(ApiPrefix.getByEnv('DOMAIN') + `/domains/${domain.id}/dns/records/`, {
            method: 'PATCH'
        });


        if (response.ok) {
            return true;
        }
        return false;
    }

}