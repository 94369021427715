import React from "react";
import AppLogo from "../Logo/AppLogo";
import AppDrawer from "../WebinifyAppPaneWidget/AppDrawer/AppDrawer";
import WebinifyAppButton from "../WebinifyAppPaneWidget/WebinifyAppButton";
import "./CommonHeader.scss";

export default class CommonHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: props.content,
      isLogo: props.isLogo,
      isMenu: props.isMenu,
      showLoginButton: props.showLoginButton || true,
      showOtherActions: props.showOtherActions != null ? props.showOtherActions : false,
    }

    this.eventListener();
  }

  eventListener() {
    var prevScrollPos = window.scrollY;

    window.onscroll = function () {
      var currentScrollPos = window.scrollY;

      if (prevScrollPos > currentScrollPos) {
        document.querySelector(".CommonHeader").classList.add("hide");
      } else {
        document.querySelector(".CommonHeader").classList.remove("hide");
      }

      prevScrollPos = currentScrollPos;
    };
  }

  showLogo() {
    if (this.state.isLogo === true) {
      return <AppLogo />;
    }
  }

  showMenu() {
    if (this.state.isMenu === true) {
      return this.state.content;
    }
  }

  showOtherActions() {
    if (this.state.showOtherActions) {
      return this.state.showOtherActions;
    }
  }



  render() {
    return <header className="CommonHeader">

      {this.showLogo()}

      <div className="menuArea">
        {this.showMenu()}
      </div>

      <div className="actions">
        {this.showOtherActions()}

        <WebinifyAppButton />
      </div>
      <AppDrawer />

    </header>;
  }
}