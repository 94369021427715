import Domain from "./Domain";


export default class domainNameServerRecord {
  constructor({ttl, priority = 0, record, type, ip, domainId}) {
    this.ttl = parseInt(ttl);
    this.priority = parseInt(priority);
    this.hostName = record;
    this.type = type;
    this.data = ip;
    this.domain = Domain.buildIri(domainId)
   
  }


  /**
     *
     * @param {Array} objectList
     * @returns {domainNameServerRecord[]}
     */
  static fromList(objectList = []) {
    if (objectList.length === 0) 
      return objectList;
    return objectList.map(o => new domainNameServerRecord(o));
  }
}