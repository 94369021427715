import React from 'react';
import "./AddDomainRecordForm.scss";
import ModalWidget from '../../../Common/Widget/ModalWidget/ModalWidget';
import DomainNSRecordApi from '../../../Api/DomainNSRecordApi';

export default class AddDomainRecordForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            record: '@',
            type: 'A',
            ip: '',
            priority: '',
            ttl: '14400'
        };


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    handleSubmit = async (event) => {
        event.preventDefault();

        const domain = this.state;
        const response = await DomainNSRecordApi.add(domain, this.props.domain);

        if (response) {
            // Process the form data here
            console.log(this.state);
            // Reset the form values
            this.setState({
                record: '',
                type: '',
                ip: '',
                priority: '',
                ttl: ''
            });

            await this.props.onSubmitSuccess();
            await this.props.close();
        }


    };


    render() {
        return (
            <ModalWidget
                extraClassNames={'add-domain'}
                visible={this.props.visible}
                title={'Add a DNS Record'}
                content={
                    <form className='add-domain'>
                        <label htmlFor="domain">Domain:</label>
                        <input
                            type="text"
                            id="domain"
                            name="domain"
                            value={this.props.domain.name}
                            disabled={true}
                        />

                        <label htmlFor="record">Record:</label>
                        <input
                            type="text"
                            id="record"
                            name="record"
                            defaultValue={this.state.record}
                            onChange={this.handleChange}
                        />

                        <label htmlFor="type">Type:</label>
                        <select
                            id="type"
                            name="type"
                            defaultValue={this.state.type}
                            onChange={this.handleChange}
                        >
                            <option value="">Select a type</option>
                            <option value="A">A</option>
                            <option value="CNAME">CNAME</option>
                            <option value="MX">MX</option>
                            <option value="TXT">TXT</option>
                            {/* Add more options as needed */}
                        </select>

                        <label htmlFor="ip">IP:</label>
                        <input
                            type="text"
                            id="ip"
                            name="ip"
                            defaultValue={this.state.ip}
                            onChange={this.handleChange}
                        />

                        <label htmlFor="priority">Priority:</label>
                        <input
                            type="text"
                            id="priority"
                            name="priority"
                            defaultValue={this.state.priority}
                            onChange={this.handleChange}
                        />

                        <label htmlFor="ttl">TTL:</label>
                        <input
                            type="text"
                            id="ttl"
                            name="ttl"
                            defaultValue={this.state.ttl}
                            onChange={this.handleChange}
                        />

                    </form>
                }
                footer={
                    <>
                        <button onClick={this.handleSubmit} className='add-new-domain' type="submit">Submit</button>
                    </>
                }
            />
        );
    }
}
