import ApiFetch from "../Common/Api/ApiFetch";
import ApiPrefix from "../Common/Api/ApiPrefix";
import Domain from "../Model/Domain";


export default class DomainApi {

    static async isAvailable(domain) {
        let url = new URL(ApiPrefix.getByEnv('DOMAIN') + `/domains/${domain}/lookup`);

        const json = await ApiFetch.cachedAuthenticated(url, {
            method: 'GET',
        }, {
            cacheExpirationTime: 60 * 1000 * 3,
            forceRefresh: false
        });

        return Domain.fromList(json)
    }

    static async getMyDomains(page = 1) {
        let url = new URL(ApiPrefix.getByEnv('DOMAIN') + "/domains/my");

        const json = await ApiFetch.cachedAuthenticated(url, {
            method: 'GET',
        }, {
            cacheExpirationTime: 60 * 1000 * 3,
            forceRefresh: false
        });

        return Domain.fromList(json)
    }

    static async save(domain) {

    }

    static async delete(id) {

    }

    static async update(domain) {

    }

}