import React from "react";

import DashboardController from "../../Common/Dashboard/DashboardController";
import SidePanelButtonWidget from "../../Common/Dashboard/SidePanelWidget/SidePanelButtonWidget/SidePanelButtonWidget";
import AuthController from "../../Common/Security/AuthController";

import SearchController from "./SearchController";
import TransfertDomainController from "./TransfertDomainController";
import { FaList } from "react-icons/fa";
import MyBillingsController from "./MyBillingsController";
import DateHandler from "../../Common/Utility/DateHandler";

import './scss/MyDashboard.scss';
import ManageDomain from "./ManageDomain";
import DomainApi from "../../Api/DomainApi";

export default class MyDashboardController extends DashboardController {
    static _PATH = '/manager/';
    constructor(props) {
        super(props);

       

        this.state = {
            isLoggedIn: props.isLoggedIn || undefined,
            domains: [],
            dashboardContent: '',
            dashboardPanelContent: '',


        }

       
    }

    componentDidMount() {
        super.componentDidMount();
        AuthController.isLoggedIn().then(isLoggedIn => {
            this.setState({isLoggedIn: isLoggedIn});

            if(isLoggedIn) {
                // get domains
                DomainApi.getMyDomains().then(domains => {
                 
                    this.setState({domains: domains});
                });

            }


        });

    }

    static RenderButton(props) {
        return <SidePanelButtonWidget text={'Domains'} path={MyDashboardController._PATH}   icon={<FaList />} />
    }



    onSidePanelClick(e, t) {
        this.updateDashboardContent();
    }

    static renderPanelContent() {
        return <>
            <SearchController.RenderButton />
            <MyDashboardController.RenderButton />
            <TransfertDomainController.RenderButton />
            <MyBillingsController.RenderButton />
       
         </>
    }

    updateDashboardContent() {
        this.setState({dashboardContentLoading: true});


        this.setState({
            dashboardContent: <>
            
            <div>Hello</div>
            </>
        });

        this.setState({dashboardContentLoading: false});
    }


    // renderDomainListing() {
    //     let lis = this.state.domains.map(d => <tr key={d.id}>{d.name}</tr>);

    //     return <ul>
    //         {lis}
    //     </ul>
    // }

    redirectToDomain(d) {
        console.log(d);

        let url = new URL(window.location.href);
        url.pathname = ManageDomain._PATH;
        url.searchParams.set('d', d.name);
        window.location.href = url.href;


    }

    renderDomainTable() {

        return <table className="domain-table">
            <thead>
                <tr> 
                    <th> Name</th>
                    <th> Status</th>
                    <th> Expire</th>
                </tr>
              
                

            </thead>
            <tbody>
                {
                    this.state.domains ?
                        this.state.domains.map(d => 
                            <tr onClick={() => this.redirectToDomain(d)} key={d.id}>
                                <td>{d.name}</td>
                                <td>{d.status.name}</td>
                                <td>{DateHandler.formatDate(d.expireAt)}</td>
                            </tr>
                        ): null
                }
            </tbody>

        </table>


    }



    // this has to be loaded when user is loaded
    loggedRender() {

       return <div className="HomePage">
            <div className="CenteredBox">
                isLoggedIn


            {this.renderDomainListing()}


            </div>  
        </div>
    }



    renderViewer() {

        return <div>

            {this.renderDomainTable()}
        </div>
    }
 

    // here we we render the one that is correct
    // we dont show anything until we know if the user is logged in or not
    render = () => {


            return super.render({
                dashboardPanelContent: MyDashboardController.renderPanelContent(),
                // dashboardPanelLoading: this.state.dashboardPanelLoading,
                dashboardContent:  this.renderViewer(),
                // dashboardContentLoading: this.state.dashboardContentLoading,k
            })
       
    }
}