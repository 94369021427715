import React from "react";
import "./FullScreenOverlayWidget.scss";

export default class FullScreenOverlayWidget extends React.Component {


    render() {

        return <div className="overlay" onClick={this.props.onClick} visible={this.props.visible}>
            {this.props.content}
        </div>
    }

}