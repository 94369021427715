export default class DomainStatus {
  constructor({id, name, expireAt}) {
    this.id = id;
    this.name = name;
    this.expireAt = expireAt;
  }

  /**
     *
     * @param {Array} objectList
     * @returns {Domain[]}
     */
  static fromList(objectList = []) {
    if (objectList.length === 0) 
      return objectList;
    return objectList.map(o => new DomainStatus(o));
  }
}