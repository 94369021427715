import React from "react";
import AuthenticatedController from "../Controller/AuthenticatedController";
import DashboardContentViewerWidget from "./ContentViewerWidget/DashboardContentViewerWidget";
import DashboardHeaderWidget from "./HeaderWidget/DashboardHeaderWidget";
import DashboardSidePanelWidget from "./SidePanelWidget/DashboardSidePanelWidget";
import "./DashboardController.scss";
import ThemeManager from "../Themes/ThemeManager";

export default class DashboardController extends AuthenticatedController {
    static _PATH = "/dashboard";
    // constructor(props) {
    //     super(props);

    // }

    componentDidMount() {
        super.componentDidMount();

    }



    render({
        dashboardPanelContent,
        dashboardPanelLoading,
        dashboardContent,
        dashboardContentLoading,
        dashboardModalContent
    }) {


        return <div className="dashboard" theme-data={ThemeManager.getTheme()}>
            <div className="viewer">
                <DashboardHeaderWidget />
                <div className="row-display">
                    <DashboardSidePanelWidget isLoading={dashboardPanelLoading} content={dashboardPanelContent} />
                    <DashboardContentViewerWidget isLoading={dashboardContentLoading} content={dashboardContent} />
                </div>
            </div>
            {dashboardModalContent}
        

        </div>
    }
}