
import DomainApi from "../../Api/DomainApi";
import DashboardController from "../../Common/Dashboard/DashboardController";
import AuthController from "../../Common/Security/AuthController";
import MyDashboardController from "./MyDashboardController";
// import { FaExchangeAlt } from "react-icons/fa";

export default class TransfertDomainController extends DashboardController {
    static _PATH = '/manager/transfert';
    constructor(props) {
        super(props);

        this.state = {
            domains: []
        }
    }

    static RenderButton(props) {
        // return <SidePanelButtonWidget text={'Transfer'} path={TransfertDomainController._PATH} icon={<FaExchangeAlt />} />
    }


    componentDidMount() {
        super.componentDidMount();
        AuthController.isLoggedIn().then(isLoggedIn => {
            this.setState({isLoggedIn: isLoggedIn});

            if(isLoggedIn) {
                // get domains
                DomainApi.getMyDomains().then(domains => {
                    this.setState({domains: domains});
                });

            }


        });
    }

    extractDomainFromUrl() {
        let url = new URL(window.location.href);

        let domain = url.searchParams.get('domain');

        return domain;
    }



    render() {

       
        return super.render({
            dashboardPanelContent: MyDashboardController.renderPanelContent(),
            dashboardContent:  this.state.dashboardContent,
        })

    }
}