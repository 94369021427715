import React from "react";
import FullScreenOverlayWidget from "../OverlayWidgets/FullScreenOverlayWidget";
import "./ModalWidget.scss";


export default class ModalWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: props.visible ?? false,
            content: props.content ?? <></>,
            footer: props.footer ?? <></>,
            title: props.title ?? "Add your title here"
        };

        this.closeViaOutOfBox = this.closeViaOutOfBox.bind(this);
        this.closeViaButton = this.closeViaButton.bind(this);
        this.show = this.show.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    show() {
        this.setState({ visible: true });
    }

    toggle() {
        this.setState({ visible: !this.state.visible });
    }

    closeViaOutOfBox(e) {
        if(e.currentTarget === e.target) {
            this.setState({ visible: false });

        }
    }

    closeViaButton() {
        this.setState({ visible: false });
    }


    render() {
        return <FullScreenOverlayWidget
            visible={this.state.visible}
            onClick={this.closeViaOutOfBox}
            content={
                <div className={"modal " + this.props.extraClassNames} >
                    <div className="header">
                        <h4>{this.state.title}</h4>
                        <button className="close" onClick={this.closeViaButton}>Close</button>
                    </div>
                    <div className="body">{this.state.content}</div>
                    <div className="footer">{this.state.footer}</div>
                </div>
            }
        />
    }
}