
import DashboardController from "../../Common/Dashboard/DashboardController";
import AuthController from "../../Common/Security/AuthController";
import MyDomainsController from "./MyDomainsController";
import ManageDomain from "./ManageDomain";
import "./scss/ManageDns.scss";
import AddDomainRecordForm from "./FormWidgets/AddDomainRecordForm";
import DomainApi from "../../Api/DomainApi";

export default class ManageDNS extends DashboardController {
    static _PATH = '/manager/dns';

    TABS = [
        {
            name: 'Default Domain Name Server',
            default: true,

        },
        {
            name: 'Custom Domain Name Server',
            default: false,
            render: <>Custom tab</>
        }
    ];



    constructor(props) {
        super(props);

        this.state = {
            modal: <></>,
            domain: {},
            domainName: null,
            tab: this.TABS.find(t => t.default === true),
        }
    }

    _toggleTabs() {
        const currentTab = this.state.tab;

        this.setState({ tab: this.TABS.find(t => t.name !== currentTab.name) })
    }



    static renderButtons() {
        return ManageDomain.renderButtons();

    }

    componentDidMount() {
        super.componentDidMount();
        this.loadContent();
    }

    loadContent = async () => {
        const isLoggedIn = await AuthController.isLoggedIn();

        this.setState({ isLoggedIn: isLoggedIn });

        if (isLoggedIn) {
            // get domains
            let url = new URL(window.location.href);
            if (!url.searchParams.has('d')) {
                window.open(MyDomainsController._PATH, '_self');
            }
            const domainName = url.searchParams.get('d');
            this.setState({ domainName: domainName }); //

            const domains = await DomainApi.getMyDomains();

            if (domains.some(d => d.name === domainName)) {
                // Check if the user has access to this domain
                const domain = domains.find(d => d.name === domainName);
                console.log('domain', domain);
                this.setState({ domain: domain });
            } else {
                // you dont have access to this one
                // redirect user to domains page
                window.open(MyDomainsController._PATH, '_self');
            }

        }
    }



    renderContent() {
        return <>
            {
                this.TABS.map(t => <button
                    className="tablinks"
                    active-tab={this.state.tab.name === t.name ? '1' : "0"}
                    onClick={() => {
                        this._toggleTabs();
                    }}
                >{t.name + (this.state.domain.isDefaultNameServers === t.default ? ' (active)' : '')}</button>)
            }

            <hr></hr>

            {
                this.state.tab.default === true ?
                    this.renderDefaultDNSTab() :
                    this.renderCustomDNSTab()

            }


        </>
    }

    renderCustomDNSTab() {
        return <>

            <div>
                <div className="table-head">
                    <h4>Custom DNS Servers</h4>
                    <button>Use custom DNS</button>
                </div>

                <table className="domain-table">
                    <thead>
                        <tr>
                            <th> <input type='checkbox'></input></th>
                            <th> Domain</th>
                            <th> IP</th>
                            <th> Actions</th>
                        </tr>



                    </thead>
                    <tbody>
                        {this.state.domain.domainNameServers ? this.state.domain.domainNameServers.filter(d => d.isDefault === false).map(d =>

                            <tr key={d.id}>
                                <td><input type='checkbox' /></td>
                                <td>{d.name}</td>
                                <td>{d.ip}</td>

                                <td>{ }</td>
                            </tr>
                        ) : ''}
                    </tbody>
                </table>
            </div>

        </>
    }

    renderDefaultDNSTab() {

        return <>

            <div>
                <div className="table-head">
                    <h4>DNS Servers</h4>
                    <button>Use default DNS</button>
                </div>
                <table className="domain-table">
                    <thead>
                        <tr>
                            <th> <input type='checkbox'></input></th>
                            <th> Domain</th>
                            <th> IP</th>
                            <th> Actions</th>
                        </tr>



                    </thead>
                    <tbody>
                        {this.state.domain.domainNameServers ? this.state.domain.domainNameServers.filter(d => d.isDefault === true).map(d =>

                            <tr key={d.id}>
                                <td><input type='checkbox' /></td>
                                <td>{d.name}</td>
                                <td>{d.ip}</td>

                                <td>{ }</td>
                            </tr>
                        ) : ''}
                    </tbody>
                </table>
            </div>


            <div>
                <div className="table-head">
                    <h4>DNS Records</h4>
                    <button onClick={this._addDns}>Add</button>
                    <button>Reset to default</button>
                    <button>Delete all</button>
                </div>
                <table className="domain-table">
                    <thead>
                        <tr>
                            <th> <input type='checkbox'></input></th>
                            <th> Record</th>
                            <th> TTL</th>
                            <th> Type</th>
                            <th> Priority</th>
                            <th> Target</th>
                            <th> Actions</th>
                        </tr>



                    </thead>
                    <tbody>
                        {this.state.domain.id ? this.state.domain.domainNameServerRecords.map(d =>
                            <tr key={d.id}>
                                <td><input type='checkbox' /></td>
                                <td>{d.hostName}</td>
                                <td>{d.ttl}</td>
                                <td>{d.type}</td>
                                <td>{d.priority}</td>
                                <td>{d.data}</td>

                                <td>{ }</td>
                            </tr>
                        ) : ''}
                    </tbody>
                </table>


            </div>
        </>


    }

    render() {

        return super.render({
            dashboardPanelContent: ManageDomain.renderButtons(),
            dashboardContent: this.renderContent(),
            dashboardModalContent: this.state.modal
        })
    }


    _addDns = () => {
        // show the add DNS form in a modal

        this.setState({ modal: <></> }, () => {
            this.setState({
                modal:
                    <AddDomainRecordForm
                        visible="true"
                        domain={this.state.domain}
                        close={
                            () => {
                                this.setState({ modal: <></> })
                            }
                        }
                        onSubmitSuccess={async () => {
                            await this.loadContent()
                        }}

                    />
            });
        })


    }
}