import React from "react";
import WebinifyAppButton from "../../Widget/WebinifyAppPaneWidget/WebinifyAppButton";
import HeaderAuthenticatedButtonWidget from "../../Widget/HeaderAuthenticatedButtonWidget/HeaderAuthenticatedButtonWidget";
import "./DashboardHeaderWidget.scss";
import AppDrawer from "../../Widget/WebinifyAppPaneWidget/AppDrawer/AppDrawer";
import AppLogo from "../../Widget/Logo/AppLogo";

export default class DashboardHeaderWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            content: props.content
        }
    }



    render(){
        return <div className="header-widget">
           <AppLogo />
            {this.state.content}
            
            <div className="actions" >
                <HeaderAuthenticatedButtonWidget />
                <WebinifyAppButton />
            </div>
            <AppDrawer />
                
        </div>
    }
}