
import { FaArrowLeft, FaSearch } from "react-icons/fa";
import DashboardController from "../../Common/Dashboard/DashboardController";
import SidePanelButtonWidget from "../../Common/Dashboard/SidePanelWidget/SidePanelButtonWidget/SidePanelButtonWidget";
import AuthController from "../../Common/Security/AuthController";
import MyDomainsController from "./MyDomainsController";
import { RxDashboard } from "react-icons/rx";
import ManageDNS from "./ManageDns";
import MyDashboardController from "./MyDashboardController";
import ManageDomainContact from "./ManageDomainContact";
import ManageDomainUserAccess from "./ManageDomainUserAccess";
import ManageDomainParameters from "./ManageDomainParameters";
import DomainApi from "../../Api/DomainApi";

export default class ManageDomain extends DashboardController {
    static _PATH = '/manager/domain';

    constructor(props) {
        super(props);

        this.state = {
            domain: {},
            domainName: null,
        }
    }



    static renderButtons() {
        let url = new URL(window.location.href);
        const currentDomain = url.searchParams.get('d');


        return <>
            <SidePanelButtonWidget
                text={`Back to domains`}
                path={MyDashboardController._PATH}
                icon={<FaArrowLeft />}
            />
            <hr />
          
            <SidePanelButtonWidget
                text={`Overview (${currentDomain})`}
                path={ManageDomain._PATH}
                icon={<RxDashboard />}
                onClick={() => {
                    let url = new URL(window.location.href);
                    url.pathname = ManageDomain._PATH;
                    window.open(url.href, '_self');
                }}
            />
            <SidePanelButtonWidget
                text={'DNS'}
                path={ManageDNS._PATH}
                icon={<FaSearch />}
                onClick={() => {
                    let url = new URL(window.location.href);
                    url.pathname = ManageDNS._PATH;
                    window.open(url.href, '_self');
                }}
            />
            <SidePanelButtonWidget
                text={'Domain parameters'}
                path={ManageDomainParameters._PATH}
                icon={<FaSearch />}
                onClick={() => {
                    let url = new URL(window.location.href);
                    url.pathname = ManageDomainParameters._PATH;
                    window.open(url.href, '_self');
                }}
            />
             <SidePanelButtonWidget
                text={'Contact informations'}
                path={ManageDomainContact._PATH}
                icon={<FaSearch />}
                onClick={() => {
                    let url = new URL(window.location.href);
                    url.pathname = ManageDomainContact._PATH;
                    window.open(url.href, '_self');
                }}
            />
            <SidePanelButtonWidget
                text={'User access'}
                path={ManageDomainUserAccess._PATH}
                icon={<FaSearch />}
                onClick={() => {
                    let url = new URL(window.location.href);
                    url.pathname = ManageDomainUserAccess._PATH;
                    window.open(url.href, '_self');
                }}
            />
        </>

    }

    componentDidMount() {
        super.componentDidMount();
        AuthController.isLoggedIn().then(isLoggedIn => {
            this.setState({isLoggedIn: isLoggedIn});

            if(isLoggedIn) {
                // get domains
                let url = new URL(window.location.href);
                if(!url.searchParams.has('d')) {
                    window.open(MyDashboardController._PATH, '_self');
                }
                const domainName = url.searchParams.get('d');
                this.setState({domainName: domainName}); //

                DomainApi.getMyDomains().then(domains => {
                   
                    if(domains.some(d => d.name === domainName)) {
                        // Check if the user has access to this domain
                        const domain = domains.find(d => d.name === domainName)
                        this.setState({domain: domain});
                    } else {
                        // you dont have access to this one
                        // redirect user to domains page
                        window.open(MyDomainsController._PATH, '_self');
                    }
                   
                });

            }


        });
    }

    renderContent() {
      
        
        return <>{this.state.domain.name}</>
    }

    render() {

        return super.render({
            dashboardPanelContent: ManageDomain.renderButtons(),
            dashboardContent: this.renderContent(),
        })
    }
}